import React from 'react'

const Poly = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="24" rx="12" fill="#d9d5d2" fillOpacity="0.5" />
      <path
        d="M15.4287 10.1306C15.1851 9.99604 14.8717 9.99604 14.5932 10.1306L12.6437 11.2404L11.3208 11.9466L9.40611 13.0564C9.16242 13.1909 8.84911 13.1909 8.57061 13.0564L7.07365 12.182C6.82996 12.0475 6.6559 11.7784 6.6559 11.4758V9.79426C6.6559 9.52522 6.79515 9.25618 7.07365 9.08803L8.57061 8.24728C8.8143 8.11276 9.12761 8.11276 9.40611 8.24728L10.9031 9.12166C11.1468 9.25618 11.3208 9.52522 11.3208 9.82789V10.9377L12.6437 10.1978V9.0544C12.6437 8.78536 12.5045 8.51632 12.226 8.34817L9.44093 6.76755C9.19724 6.63303 8.88392 6.63303 8.60542 6.76755L5.75076 8.3818C5.47226 8.51632 5.33301 8.78536 5.33301 9.0544V12.2156C5.33301 12.4847 5.47226 12.7537 5.75076 12.9219L8.57061 14.5025C8.8143 14.637 9.12761 14.637 9.40611 14.5025L11.3208 13.4263L12.6437 12.6864L14.5584 11.6103C14.8021 11.4758 15.1154 11.4758 15.3939 11.6103L16.8909 12.451C17.1346 12.5856 17.3086 12.8546 17.3086 13.1573V14.8388C17.3086 15.1078 17.1694 15.3769 16.8909 15.545L15.4287 16.3858C15.1851 16.5203 14.8717 16.5203 14.5932 16.3858L13.0963 15.545C12.8526 15.4105 12.6785 15.1414 12.6785 14.8388V13.7626L11.3556 14.5025V15.6123C11.3556 15.8813 11.4949 16.1503 11.7734 16.3185L14.5932 17.8991C14.8369 18.0336 15.1502 18.0336 15.4287 17.8991L18.2486 16.3185C18.4923 16.184 18.6663 15.9149 18.6663 15.6123V12.4174C18.6663 12.1484 18.5271 11.8793 18.2486 11.7112L15.4287 10.1306Z"
        fill="var(--theme-ui-colors-text)"
      />
    </svg>
  )
}

export default Poly
