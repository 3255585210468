import * as React from 'react'

const Icon: React.FC<{ fill?: string; color?: string }> = (props) => {
  return (
    <svg width="31" height="47" viewBox="0 0 31 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.5574 0.579998C19.9174 0.579998 23.4174 1.7 26.0574 3.94C28.6974 6.18 30.0174 9.38 30.0174 13.54C30.0174 17.18 28.7974 20.06 26.3574 22.18C23.9574 24.3 20.7774 25.4 16.8174 25.48L16.5174 29.86H6.13742L5.77742 17.92H10.0974C12.8174 17.92 14.8974 17.62 16.3374 17.02C17.7774 16.38 18.4974 15.26 18.4974 13.66C18.4974 12.58 18.1974 11.74 17.5974 11.14C17.0374 10.5 16.2574 10.18 15.2574 10.18C14.1374 10.18 13.2774 10.52 12.6774 11.2C12.1174 11.84 11.8374 12.72 11.8374 13.84H0.737422C0.657422 11.4 1.15742 9.18 2.23742 7.18C3.35742 5.14 5.03742 3.54 7.27742 2.38C9.51742 1.18 12.2774 0.579998 15.5574 0.579998ZM11.4174 46.48C9.33742 46.48 7.65742 45.92 6.37742 44.8C5.13742 43.64 4.51742 42.18 4.51742 40.42C4.51742 38.66 5.13742 37.2 6.37742 36.04C7.65742 34.88 9.33742 34.3 11.4174 34.3C13.4574 34.3 15.0974 34.88 16.3374 36.04C17.6174 37.2 18.2574 38.66 18.2574 40.42C18.2574 42.14 17.6174 43.58 16.3374 44.74C15.0974 45.9 13.4574 46.48 11.4174 46.48Z" />
    </svg>
  )
}

export default Icon
